import React, { useContext,useState ,useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";
import FlagIcon from "../assets/imagesNew/india-icon.png";
import FlagIconBn from "../assets/imagesNew/bangladesh-flag-round-circle-icon.png";

const LoginNotification = () => {
    let {setShowLoginNotification,lang, setEn, setBr} = useContext(AuthContext);

  const {t} = useTranslation()
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowLoginNotification(false);
      }}
    >
      
    
      <div className="login-notification slide-up">
       
      <div id="popInbox" className="pop-wrap">
        <a className="btn-close "   onClick={() => setShowLoginNotification(false)}></a>
        <div className="pop-title ">
          <h3 className=""> Notification </h3>
        </div>
        <div className="pop-inner ">
          <p className="">Please log in or sign up to play the game.</p>
          
          <div className="login-register-button btn-box">
            <div className="button login-button">
              
              <Link to="/login" className="" onClick={() => setShowLoginNotification(false)}>{t("Login")}</Link> 
          </div>
             <div className="button btn-primary register-button">
       
            <Link to="#" className="" onClick={() => setShowLoginNotification(false)}>{t("Sign_up")}</Link>
          </div>
          </div>
          </div>
        </div>
         
          
         </div>
     
    
      
    </OutsideClickHandler>
  );
};

export default LoginNotification;
